import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./custom.scss";
import App from "./App";
import "bootstrap/dist/js/bootstrap";
import "font-awesome/css/font-awesome.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import * as Oidc from "oidc-client";

Oidc.Log.logger = console;

if (!Intl.PluralRules) {
  require("intl-pluralrules");
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  //require('@formatjs/intl-relativetimeformat/dist/include-aliases'); // Optional, if you care about edge cases in locale resolution, e.g zh-CN -> zh-Hans-CN
  require("@formatjs/intl-relativetimeformat/locale-data/sv"); // Add locale data
  require("@formatjs/intl-relativetimeformat/locale-data/en"); // Add locale data
}

createRoot(document.getElementById("root")).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
