import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import Loading from "./components/Shared/Loading";

import { getAppInsights } from "./components/AppInsights/TelemetryService";
import TelemetryProvider from "./components/AppInsights/telemetry-provider";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
const Home = lazy(() => import("./components/Home/Home"));
const Callback = lazy(() => import("./components/Auth/Callback"));
const SilentCallback = lazy(() => import("./components/Auth/SilentCallback"));
const Login = lazy(() => import("./components/Auth/Login"));
const Status403 = lazy(() => import("./components/Auth/Status403"));
const InviteHandler = lazy(() => import("./components/Invite/InviteHandler"));

const App = () => {
  return (
    <>
      <Router>
        <TelemetryProvider
          instrumentationKey={window._env_.INSTRUMENTATION_KEY}
          after={() => {
            getAppInsights();
          }}
        >
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/oidc-callback" element={<Callback />} />
              <Route path="/oidc-silentcallback" element={<SilentCallback />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/403" element={<Status403 />} />
              <Route exact path="/invite/:id" element={<InviteHandler />} />
              <Route index path="/*" element={<Home />} />
            </Switch>
          </Suspense>
        </TelemetryProvider>
      </Router>
      <ToastContainer
        position="bottom-right"
        closeButton={false}
        hideProgressBar
        transition={Slide}
        className="toast-container"
      />
    </>
  );
};

export default App;
